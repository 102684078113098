import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AdvicePage = () => {

  return (
    <Layout >
      <SEO title="Advice" />
      <h1>Advice</h1>
      <p><h5>If you need advice or have any specific questions, you can email me at: <b>theclassynurse22@gmail.com</b></h5></p>
    </Layout>
  )
}

export default AdvicePage